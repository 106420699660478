import { TableLayoutV2 } from 'typ'
import { StoreSlice } from '../types'

export type PpaTableField =
  | 'readableId'
  | 'link'
  | 'type'
  | 'capacity'
  | 'startDate'
  | 'term'
  | 'offtaker'
  | 'status'
  | 'totalAssets'
  | 'userUpdatedAt'
  | 'createdAt'

const initialLayout: TableLayoutV2<PpaTableField> = {
  displayedCols: ['readableId', 'link', 'type', 'capacity', 'status', 'startDate'],
}

export interface PpasListSlice {
  // TABLE LAYOUT
  ppasListLayout: TableLayoutV2<PpaTableField>
  setPpasListLayout: (layout: Partial<TableLayoutV2<PpaTableField>>) => void
}
export const createPpasListSlice: StoreSlice<PpasListSlice> = (set, _get) => ({
  // TABLE LAYOUT

  ppasListLayout: {
    ...initialLayout,
  },
  setPpasListLayout: (layout: Partial<TableLayoutV2<PpaTableField>>) =>
    set((state) => ({
      ppasListLayout: { ...state.ppasListLayout, ...layout },
    })),
})
