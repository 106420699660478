import { makeVar } from '@apollo/client'
import { useHandleGenericServerError } from 'common-v2/error-helpers/useHandleGenericServerError'
import {
  SimulationStatus,
  useTriggerSolarAssetsSimulationMutation,
} from 'generated/tensor-api/generated-types'
import { useCallback } from 'react'
import store from 'store'

export const localSimulationStatusVar = makeVar<
  Record<string, { status: SimulationStatus; date: Date }>
>({})

export const useTriggerSimulations = () => {
  const handleGenericServerError = useHandleGenericServerError()
  const workspaceId = store.useGetCurrentWorkspaceId()()

  const [triggerSimulations, mutationState] = useTriggerSolarAssetsSimulationMutation({
    onCompleted: ({
      solarAssets: {
        triggerManySimulations: { errors },
      },
    }) => {
      if (errors?.length) {
        handleGenericServerError('trigger-simulations-error')
      }
    },
    onError: () => handleGenericServerError('trigger-simulations-error'),
  })

  const handleRerunSimulation = useCallback(
    async (ids: string[]) => {
      const { data } = await triggerSimulations({
        variables: { input: { ids, workspaceId } },
      })
      if ((data?.solarAssets.triggerManySimulations.errors?.length ?? 0) <= 0) {
        localSimulationStatusVar({
          ...localSimulationStatusVar(),
          ...Object.fromEntries(
            ids.map((id) => [id, { status: SimulationStatus.Running, date: new Date() }])
          ),
        })
      }
    },
    [triggerSimulations, workspaceId]
  )

  return [handleRerunSimulation, mutationState] as const
}
