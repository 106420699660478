import { TableLayoutV2 } from 'typ'
import { StoreSlice } from '../types'

export type AssetTableField =
  | 'acCapacity'
  | 'cod'
  | 'dcCapacity'
  | 'gridZone'
  | 'locationName'
  | 'link'
  | 'ppa'
  | 'readableId'
  | 'status'
  | 'simulationStatus'
  | 'userUpdatedAt'
  | 'createdAt'

const initialLayout: TableLayoutV2<AssetTableField> = {
  displayedCols: [
    'link',
    'readableId',
    'acCapacity',
    'locationName',
    'simulationStatus',
    'gridZone',
  ],
}

export interface SolarAssetsListSlice {
  // TABLE LAYOUT
  solarAssetsLayout: TableLayoutV2<AssetTableField>
  setSolarAssetsLayout: (layout: Partial<TableLayoutV2<AssetTableField>>) => void
}

export const createSolarAssetsListSlice: StoreSlice<SolarAssetsListSlice> = (set, _get) => ({
  // TABLE LAYOUT

  solarAssetsLayout: {
    ...initialLayout,
  },
  setSolarAssetsLayout: (layout: Partial<TableLayoutV2<AssetTableField>>) =>
    set((state) => ({
      solarAssetsLayout: { ...state.solarAssetsLayout, ...layout },
    })),
})
