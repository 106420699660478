import { UserElectricalPowerUnit } from 'generated/tensor-api/generated-types'
import { PaginatedListSize, StoreSlice } from '../types'

export interface UserPreferencesSlice {
  paginatedListSize: PaginatedListSize
  setPaginatedListSize: (size: PaginatedListSize) => void
  isSideNavCollapsed: boolean
  setIsSideNavCollapsed: (collapsed: boolean) => void
  isShowingOnlyUnreadNotifications: boolean
  setIsShowingOnlyUnreadNotifications: (showing: boolean) => void
  toggleIsSideNavCollapsed: VoidFunction
  expandedSideNavSections: string[]
  setExpandedSideNavSections: (sections: string[]) => void
  preferredElectricalUnit: UserElectricalPowerUnit
  setPreferredElectricalUnit: (unit: UserElectricalPowerUnit) => void
  preferredForecastsLength: string
  setPreferredForecastsLength: (length: string) => void
}
export const createUserPreferencesSlice: StoreSlice<UserPreferencesSlice> = (set, _get) => ({
  paginatedListSize: 10,
  setPaginatedListSize: (size: PaginatedListSize) => set(() => ({ paginatedListSize: size })),
  isSideNavCollapsed: false,
  setIsSideNavCollapsed: (collapsed: boolean) => set(() => ({ isSideNavCollapsed: collapsed })),
  toggleIsSideNavCollapsed: () =>
    set((state) => ({ isSideNavCollapsed: !state.isSideNavCollapsed })),
  preferredElectricalUnit: UserElectricalPowerUnit.Kilowatt,
  setPreferredElectricalUnit: (unit: UserElectricalPowerUnit) =>
    set(() => ({ preferredElectricalUnit: unit })),
  isShowingOnlyUnreadNotifications: false,
  setIsShowingOnlyUnreadNotifications: (showing: boolean) =>
    set(() => ({ isShowingOnlyUnreadNotifications: showing })),
  expandedSideNavSections: [],
  setExpandedSideNavSections: (sections: string[]) =>
    set(() => ({ expandedSideNavSections: sections })),
  preferredForecastsLength: '3',
  setPreferredForecastsLength: (length: string) =>
    set(() => ({ preferredForecastsLength: length })),
})
