/* eslint-disable sonarjs/cognitive-complexity */
import clsx from 'clsx'
import { ComponentType, PropsWithChildren, forwardRef, useId, useState } from 'react'
import {
  mergeProps,
  useButton,
  useFocus,
  useFocusVisible,
  useHover,
  useObjectRef,
} from 'react-aria'
import { ButtonProps as AriaButtonProps } from 'react-aria-components'
import { TestProps } from 'typ'
import { isNil } from 'typeguards'
import { useLocale } from '../../hooks/useLocale'
import { SvgIconProps } from '../../icons/types'
import { LoaderWrapper } from '../loader'
import {
  Contrast,
  Variant,
  disabledCx,
  focusVisibleCx,
  hoveredCx,
  pressedCx,
  wrapperCx,
} from './Button.css'
import { CoreTensorButtonProps } from './types'
import { getButtonIconSize } from './utils/get-button-icon-size'
import { getButtonLayout } from './utils/get-button-layout'
import { getComputedClassName } from './utils/get-computed-class-name'

type ButtonIconProps = {
  LeftIcon?: ComponentType<SvgIconProps>
  RightIcon?: ComponentType<SvgIconProps>
}
export type CoreButtonProps = Omit<
  AriaButtonProps,
  'children' | 'form' | 'formAction' | 'formEncType' | 'formMethod' | 'formNoValidate' | 'className'
> &
  PropsWithChildren &
  CoreTensorButtonProps &
  ButtonIconProps &
  TestProps & {
    as?: React.ElementType
    isLoaderWrapperDisabled?: boolean
  }

export type DefaultButtonProps = CoreButtonProps & {
  variant: Exclude<Variant, 'text' | 'solid'>
  contrast?: Contrast
  isLoading?: boolean
}

export type TextButtonProps = CoreButtonProps & {
  variant: 'text'
  contrast?: Contrast
  isLoading?: never
}

export type SolidButtonProps = CoreButtonProps & {
  variant: 'solid'
  contrast?: never
  isLoading?: boolean
}

export type ButtonProps = DefaultButtonProps | TextButtonProps | SolidButtonProps

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as: Component = 'button',
      variant,
      size = 'md',
      children,
      className,
      LeftIcon,
      RightIcon,
      color,
      isDisabled,
      width = 'auto',
      contrast = 'default',
      isLoading = false,
      onPress,
      isLoaderWrapperDisabled,
      ...props
    },
    forwardedRef
  ) => {
    const { locale } = useLocale()
    const ref = useObjectRef(forwardedRef)
    const { isFocusVisible } = useFocusVisible()
    const [isFocused, onFocusChange] = useState(false)
    const { focusProps } = useFocus({ onFocusChange, isDisabled })
    const { hoverProps, isHovered } = useHover({ isDisabled })
    const { buttonProps, isPressed } = useButton({ ...props, onPress }, ref)
    const mergedProps = mergeProps(buttonProps, hoverProps, focusProps, props)
    const computedClassName = getComputedClassName(className, {
      isFocused,
      isHovered,
      isPressed,
      isFocusVisible,
    })

    const backupId = useId()
    const buttonId = mergedProps.id ?? backupId

    const layout = getButtonLayout({ LeftIcon, RightIcon })

    const loaderColor = variant === 'solid' ? 'disabledBackground' : 'disabledContent'

    const hasAriaLabel = !!mergedProps['aria-label']
    const loadingString = locale === 'en' ? 'loading' : 'ロード中'
    const isLoadingAriaLiveLabel = `${
      hasAriaLabel ? mergedProps['aria-label'] : ''
    } ${loadingString}`.trim()

    const loaderWrapperProps = {
      color: loaderColor,
      isActive: !isLoaderWrapperDisabled,
    } as const

    const iconSize = getButtonIconSize(size)
    return (
      <Component
        {...mergedProps}
        id={buttonId}
        ref={ref}
        className={clsx([
          wrapperCx({ size, color, variant, layout, contrast, width }),
          isHovered && hoveredCx({ variant, color, contrast }),
          isPressed && pressedCx({ variant, color }),
          isFocusVisible && isFocused && focusVisibleCx({ color }),
          (isDisabled || isLoading) && disabledCx({ variant }),
          computedClassName,
        ])}
        data-color={color}
        data-size={size}
        data-variant={variant}
        aria-disabled={isLoading ? 'true' : undefined}
        aria-label={isLoading ? isLoadingAriaLiveLabel : buttonProps['aria-label']}
        // We might want to add an aria-labelledby here but it is probably not necessary
        disabled={isDisabled || isLoading}
      >
        {
          <>
            {LeftIcon && (
              <LoaderWrapper {...loaderWrapperProps} size={iconSize} isLoading={isLoading}>
                <LeftIcon size={size} />
              </LoaderWrapper>
            )}
            <LoaderWrapper
              {...loaderWrapperProps}
              size={size}
              isLoading={isLoading && isNil(LeftIcon) && isNil(RightIcon)}
            >
              {children}
            </LoaderWrapper>
            {RightIcon && (
              <LoaderWrapper
                {...loaderWrapperProps}
                size={iconSize}
                isLoading={isLoading && isNil(LeftIcon)}
              >
                <RightIcon size={iconSize} />
              </LoaderWrapper>
            )}
          </>
        }
      </Component>
    )
  }
)

Button.displayName = 'Button'
