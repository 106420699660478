import { useRouter } from 'next/router'
import { FC, ReactNode, createContext, memo, useContext, useEffect, useMemo, useRef } from 'react'

type RouterHistoryContextValue = {
  isBackForward: boolean
}

const context = createContext<RouterHistoryContextValue | null>(null)

export const RouterHistoryContextProvider: FC<{ children: ReactNode }> = memo(({ children }) => {
  const router = useRouter()
  const isBackForwardRef = useRef(false)

  useEffect(() => {
    // Gets fired when the user navigates to a new route
    // This happens before the popstate event
    const handleRouteChangeStart = () => {
      isBackForwardRef.current = false
    }

    // Gets fired when the user navigates back or forward
    const handlePopState = () => {
      isBackForwardRef.current = true
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    window.addEventListener('popstate', handlePopState)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      window.removeEventListener('popstate', handlePopState)
    }
  }, [router.events])

  const isBackForward = isBackForwardRef.current
  const value = useMemo(() => ({ isBackForward }), [isBackForward])

  return <context.Provider value={value}>{children}</context.Provider>
})

export const MockRouterHistoryContextProvider: FC<{
  isBackForward: boolean
  children: ReactNode
}> = ({ children, isBackForward }) => {
  const value = useMemo(() => ({ isBackForward }), [isBackForward])
  return <context.Provider value={value}>{children}</context.Provider>
}

RouterHistoryContextProvider.displayName = 'RouterHistoryContextProvider'

export const useRouterHistoryContext = (shouldThrowErrorOnNull = true) => {
  const value = useContext(context)
  if (value === null && shouldThrowErrorOnNull) {
    throw new Error(
      'useRouterHistoryContext must be used within a RouterHistoryContextProvider please add it to a high place in the application'
    )
  }
  return value ?? { isBackForward: false }
}
