import { InMemoryCacheConfig } from '@apollo/client'
import { localSimulationStatusVar } from 'assets/solar/utils/useTriggerSimulations'
import possibleTypes from 'generated/tensor-api/possibleTypes.json'

const assumptionScenariosMerge = {
  scenarios: {
    merge: (_: unknown, incoming: unknown[]) => incoming,
  },
}

export const tensorCloudCachePolicies: InMemoryCacheConfig = {
  possibleTypes,
  typePolicies: {
    Actor: {
      fields: {
        profilePicture: {
          merge: true,
        },
      },
    },
    SimulationSummary: {
      merge: true,
    },
    ScenarioInflation: {
      fields: {
        ...assumptionScenariosMerge,
      },
    },
    UserAccount: {
      fields: {
        preferences: {
          merge: true,
        },
      },
    },
    ScenarioCurtailment: {
      fields: {
        ...assumptionScenariosMerge,
      },
    },
    ScenarioForecastMapeDayAhead: {
      fields: {
        ...assumptionScenariosMerge,
      },
    },
    ScenarioForecastMapeIntraday: {
      fields: {
        ...assumptionScenariosMerge,
      },
    },
    ScenarioPriceAverage: {
      fields: {
        ...assumptionScenariosMerge,
      },
    },
    ScenarioPriceVolatility: {
      fields: {
        ...assumptionScenariosMerge,
      },
    },
    BalancingGroup: {
      fields: {
        forecasts: {
          merge: true,
        },
      },
    },
    SolarAssetOperations: {
      merge: true,
    },
    Battery: {
      merge: true,
    },
    Report: {
      fields: {
        scope: { merge: true },
      },
    },
    SolarAsset: {
      fields: {
        simulationStatus: {
          read: (status, { readField }) => {
            const id = readField<string>('id')
            const updatedAt = readField<string>('updatedAt')
            if (!id || !updatedAt) return status

            const localStatus = localSimulationStatusVar()[id]
            return localStatus && localStatus.date > new Date(updatedAt)
              ? localStatus.status
              : status
          },
        },
      },
    },
    User: {
      keyFields: ['id', 'workspace', ['id']],
      fields: {
        preferences: {
          merge: true,
        },
      },
    },
    Spv: {
      fields: {
        legalEntityName: {
          merge: true,
        },
        assets: {
          merge: false,
        },
      },
    },
    FinancialTransaction: {
      fields: {
        debit: {
          merge: true,
        },
        metadata: {
          merge: true,
        },
      },
    },
    Query: {
      fields: {
        scenarioList: {
          merge: true,
        },
        spvList: {
          merge: true,
        },
        marketIntelligence: {
          merge: true,
        },
        notifications: {
          merge: false,
        },
        scenarioAssumption: {
          merge: true,
        },
        scenarioAssumptions: {
          merge: true,
        },
        jepxTradingHistory: {
          keyArgs: ['input', ['workspaceId']],
          merge(existing: any[] = [], incoming: any[] = []) {
            const output = [...existing]
            incoming.forEach((item) => {
              const index = output.findIndex((e) => e.__ref === item.__ref)
              if (index >= 0) {
                output[index] = item
              } else {
                output.push(item)
              }
            })
            return output
          },
        },
      },
    },
  },
}
