/**
 * Parse Japanese era date string to Date object
 *
 * @param date e.g. 令和06年04月30日
 *
 * @returns Date object
 */

const ERAS = [
  {
    name: '昭和',
    start: new Date('1926-12-25'),
    end: new Date('1989-01-07'),
  },
  {
    name: '平成',
    start: new Date('1989-01-08'),
    end: new Date('2019-04-30'),
  },
  {
    name: '令和',
    start: new Date('2019-05-01'),
    end: new Date('9999-12-31'),
  },
]

export const parseJapaneseEraDate = (date: string): Date => {
  const eraNames = ERAS.map((era) => era.name)
  const regex = new RegExp(`^(${eraNames.join('|')})([0-9]{1,2})年([0-9]{1,2})月([0-9]{1,2})日$`)
  const match = date.match(regex)

  if (!match) throw new Error(`Invalid date format ${date}`)
  const [_, eraName, yearString, monthString, dayString] = match
  const era = ERAS.find((e) => e.name === eraName)

  if (!era) throw new Error(`Invalid era ${eraName}`)
  const year = parseInt(yearString, 10)
  const month = parseInt(monthString, 10)
  const day = parseInt(dayString, 10)
  const eraYear = era.start.getFullYear() + year - 1
  return new Date(eraYear, month - 1, day)
}
