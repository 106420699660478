import { DOMAttributes } from '@react-types/shared'
import clsx from 'clsx'
import { AnimatePresence, ValueAnimationTransition, useAnimate } from 'framer-motion'
import { useMergedRef, usePrevious } from 'hooks'
import { ReactNode, forwardRef, useEffect, useRef, useState } from 'react'
import {
  AriaPositionProps,
  OverlayContainer,
  mergeProps,
  useOverlayPosition,
  useTooltip,
} from 'react-aria'
import { TooltipTriggerState } from 'react-stately'
import { TestProps } from 'typ'
import { arrowCx, tooltipCx } from './Tooltip.css'

export interface TooltipProps
  extends TestProps,
    DOMAttributes,
    Pick<AriaPositionProps, 'placement' | 'offset' | 'crossOffset'> {
  children: ReactNode
  state: TooltipTriggerState
  triggerRef: React.RefObject<HTMLDivElement>
  className?: string
}

const transition: ValueAnimationTransition = { ease: [0, 0, 0.04, 0.99], duration: 0.1 }

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      children,
      triggerRef,
      state,
      offset = 9,
      crossOffset = 0,
      placement = 'top',
      className,
      ...props
    },
    ref
  ) => {
    const tooltipRef = useRef<HTMLDivElement | null>(null)
    const [scope, animate] = useAnimate()
    const mergedRef = useMergedRef<HTMLDivElement>(tooltipRef, ref, scope)
    const { tooltipProps } = useTooltip(props, state)
    const [isExiting, setIsExiting] = useState(false)
    const previousIsOpen = usePrevious(state.isOpen)

    const {
      overlayProps,
      arrowProps,
      placement: placementResult,
      updatePosition,
    } = useOverlayPosition({
      targetRef: triggerRef,
      overlayRef: tooltipRef,
      offset,
      crossOffset,
      placement,
      arrowSize: 0,
      arrowBoundaryOffset: 4,
      isOpen: state.isOpen,
      shouldUpdatePosition: true,
    })

    useEffect(() => {
      if (state.isOpen) {
        ;(async () => {
          await animate(scope.current, { opacity: 1, scale: 1 }, transition)
          updatePosition()
        })()
      } else if (previousIsOpen && !state.isOpen) {
        ;(async () => {
          setIsExiting(true)
          await animate(scope.current, { opacity: 0, scale: 0.97 }, transition)
          setIsExiting(false)
        })()
      }
    }, [animate, scope, state.isOpen, previousIsOpen, updatePosition])

    const origin = arrowProps.style?.left ?? arrowProps.style?.right ?? 0
    const originMap = {
      top: `${origin}px bottom`,
      left: `${origin}px right`,
      right: `${origin}px left`,
      bottom: `${origin}px top`,
      center: 'inherit',
    }
    const nonNullPlacement = placementResult ?? 'bottom'
    return (
      <AnimatePresence>
        {(state.isOpen || isExiting) && (
          <OverlayContainer>
            <div
              key="tooltip"
              ref={mergedRef}
              className={clsx(tooltipCx, className)}
              style={{
                transformOrigin: originMap[nonNullPlacement],
              }}
              {...mergeProps(props, tooltipProps, overlayProps)}
            >
              <div {...arrowProps} className={arrowCx({ placement: nonNullPlacement })} />
              {children}
            </div>
          </OverlayContainer>
        )}
      </AnimatePresence>
    )
  }
)

Tooltip.displayName = 'Tooltip'
