import { SortOrder } from './types'

type Numeric = number | string | null
type Options = { order?: SortOrder }

export function sortNumeric<T extends Numeric | Record<string, any>>(
  arr: T[],
  options?: Options | null,
  accessor: (el: T) => Numeric = (el) => el as unknown as Numeric
): T[] {
  const order = options?.order ?? 'asc'

  return [...arr].sort((a, b) => {
    const accessedA = accessor(a)
    const accessedB = accessor(b)

    const normalizeValue = (val: Numeric) => {
      if (
        val === null ||
        val === undefined ||
        (typeof val === 'string' && !/^[-+]?\d*(\.\d+)?$/.test(val.trim()))
      ) {
        return 0
      }
      return Number(val)
    }

    const valA = normalizeValue(accessedA)
    const valB = normalizeValue(accessedB)

    return order === 'asc' ? valA - valB : valB - valA
  })
}
