import { ErrorResponse } from '@apollo/client/link/error'
import { GraphQLError, GraphQLFormattedError } from 'graphql'

export type GraphQLErrorMetadata = Omit<ErrorResponse, 'graphQLErrors' | 'networkError'>

export const formatGqlError = (
  error: GraphQLError | GraphQLFormattedError,
  metadata: GraphQLErrorMetadata
) => `[GraphQL error]:  
  *** MESSAGE: ${error.message} 
  *** LOCATION: ${JSON.stringify(error.locations)} 
  *** PATH: ${JSON.stringify(error.path)}
  *** OPERATION_NAME: ${JSON.stringify(metadata.operation.operationName)}
  *** CODE: ${JSON.stringify(error.extensions?.code)}
  *** VARIABLES: ${JSON.stringify(metadata.operation.variables)}`
