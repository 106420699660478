import clsx from 'clsx'
import { AriaRole, PropsWithChildren, forwardRef } from 'react'
import { TestProps } from 'typ'
import { sprinkles } from 'vanilla-extract-config/sprinkles'
import { BoxLayoutProps } from '../../types'
import { extractBoxLayoutProps } from '../../utils'
import { FontVariant, textCx } from './Text.css'

export interface TextProps extends PropsWithChildren, TestProps, BoxLayoutProps {
  as?: React.ElementType
  variant?: FontVariant
  ellipsize?: boolean
  flattenLineHeight?: boolean
  className?: string
  role?: AriaRole
  'aria-label'?: string
}
export const Text = forwardRef<HTMLDivElement, TextProps>(
  (
    {
      as: Component,
      children,
      className,
      ellipsize,
      variant = 'labelMd',
      flattenLineHeight,
      m = '000',
      ...props
    },
    ref
  ) => {
    const Element = Component || 'div'
    const { layoutProps, testProps, colorProps, ...coreProps } = extractBoxLayoutProps(props)

    return (
      <Element
        {...testProps}
        {...coreProps}
        className={clsx([
          sprinkles({ ...layoutProps, m, ...colorProps }),
          textCx({ variant, ellipsize, flattenLineHeight }),
          className,
        ])}
        ref={ref}
      >
        {children}
      </Element>
    )
  }
)

Text.displayName = 'Text'
