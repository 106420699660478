import { TableLayoutV2 } from 'typ'
import { StoreSlice } from '../types'

export type ReportsTableField = 'readableId' | 'link' | 'assets' | 'ppas' | 'createdAt' | 'author'

const initialLayout: TableLayoutV2<ReportsTableField> = {
  displayedCols: ['readableId', 'link', 'assets', 'ppas', 'createdAt', 'author'],
}

export interface ReportsListSlice {
  // TABLE LAYOUT
  reportsListLayout: TableLayoutV2<ReportsTableField>
  setReportsListLayout: (layout: Partial<TableLayoutV2<ReportsTableField>>) => void
}

export const createReportsSlice: StoreSlice<ReportsListSlice> = (set, _get) => ({
  // TABLE LAYOUT

  reportsListLayout: {
    ...initialLayout,
  },
  setReportsListLayout: (layout: Partial<TableLayoutV2<ReportsTableField>>) =>
    set((state) => ({
      reportsListLayout: { ...state.reportsListLayout, ...layout },
    })),
})
