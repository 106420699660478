import { FC, PropsWithChildren } from 'react'
import { TestProps } from 'typ'
import { sprinkles } from 'vanilla-extract-config/sprinkles'
import { Loader, LoaderProps } from './Loader'
import { wrapperCx } from './LoaderWrapper.css'

export interface LoaderWrapperProps
  extends Pick<LoaderProps, 'size' | 'color'>,
    PropsWithChildren,
    TestProps {
  isLoading: boolean | undefined
  isActive?: boolean
}
export const LoaderWrapper: FC<LoaderWrapperProps> = ({
  size,
  color,
  isLoading,
  children,
  isActive = true,
  ...props
}) =>
  !isActive ? (
    children
  ) : (
    <div className={wrapperCx({ size })} {...props}>
      <div
        hidden={!isLoading}
        className={sprinkles({
          position: 'absolute',
          display: isLoading ? 'grid' : 'none',
          place: 'center',
        })}
      >
        <Loader size={size} color={color} isLoading={isLoading} />
      </div>
      <div
        hidden={isLoading}
        className={sprinkles({
          visibility: isLoading ? 'hidden' : 'visible',
          display: 'grid',
          place: 'center',
        })}
      >
        {children}
      </div>
    </div>
  )
