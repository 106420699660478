import { TableLayoutV2 } from 'typ'
import { StoreSlice } from '../types'

export type BalancingGroupsTableField =
  | 'bgCode'
  | 'link'
  | 'gridZone'
  | 'totalAssets'
  | 'updatedAt'
  | 'createdAt'

const initialLayout: TableLayoutV2<BalancingGroupsTableField> = {
  displayedCols: ['bgCode', 'link', 'gridZone', 'totalAssets', 'updatedAt', 'createdAt'],
}

export interface BalancingGroupsListSlice {
  // TABLE LAYOUT
  balancingGroupsListLayout: TableLayoutV2<BalancingGroupsTableField>
  setBalancingGroupsListLayout: (layout: Partial<TableLayoutV2<BalancingGroupsTableField>>) => void
}

export const createBalancingGroupsSlice: StoreSlice<BalancingGroupsListSlice> = (set, _get) => ({
  // TABLE LAYOUT

  balancingGroupsListLayout: {
    ...initialLayout,
  },
  setBalancingGroupsListLayout: (layout: Partial<TableLayoutV2<BalancingGroupsTableField>>) =>
    set((state) => ({
      balancingGroupsListLayout: {
        ...state.balancingGroupsListLayout,
        ...layout,
      },
    })),
})
