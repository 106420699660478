import { TableLayoutV2 } from 'typ'
import { StoreSlice } from '../types'

export type SpvsTableField =
  | 'readableId'
  | 'jaName'
  | 'enName'
  | 'dcCapacity'
  | 'totalAssets'
  | 'updatedAt'
  | 'createdAt'

const initialLayout: TableLayoutV2<SpvsTableField> = {
  displayedCols: [
    'readableId',
    'jaName',
    'enName',
    'dcCapacity',
    'totalAssets',
    'updatedAt',
    'createdAt',
  ],
}

export interface SpvsListSlice {
  // TABLE LAYOUT
  spvsListLayout: TableLayoutV2<SpvsTableField>
  setSpvsListLayout: (layout: Partial<TableLayoutV2<SpvsTableField>>) => void
}

export const createSpvsListSlice: StoreSlice<SpvsListSlice> = (set, _get) => ({
  // TABLE LAYOUT
  spvsListLayout: {
    ...initialLayout,
  },
  setSpvsListLayout: (layout: Partial<TableLayoutV2<SpvsTableField>>) =>
    set((state) => ({
      spvsListLayout: { ...state.spvsListLayout, ...layout },
    })),
})
